.f-f-d{
  font-size: 11px;
  text-transform: uppercase;
  color: #999;
  line-height: 16px;
  height: 16px;
  margin: 0px;
  margin-bottom: 10px;
  text-decoration: none;
}

.f-h-d{
  font-size: 20px;
  line-height: 25px;
  margin: 0;
  height: 50px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #242424;
}

.f-p-d{
  font-size: 14px;
  line-height: 23px;
  margin: 10px 0 0;
  margin-bottom: 10px;
  color: #999;
}

.u-d{
  text-align: left;
  color: #999;
}

.maingrid {
  flex-wrap: wrap;
  display: flex;
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  gap: 10px 1%;
  margin-top: 100px;
  margin-bottom: 100px;
}

.Pformate {
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.maingrid .griditem {
  width: 32.4% !important;
  text-decoration: none;
}

.maingrid .griditem img {
  width: 100%;
  object-fit: cover;
}

.footer-line{
  text-align: center;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 12px;
}

.footer{
  position: relative;
  background: #333f50;
  color: #fff;
  height: auto;
  padding: 20px 0;
}

img.logo {
  height: 40px;
  width: auto;
  margin-top: 4px;
}

.page-links a {
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  display: block;
  text-decoration: none;
}

.page-links {
  display: flex;
  gap: 10px;
}

.page-links a:hover {
  color: blue;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .09);
  z-index: 999999;
  background: white;
  width: 100%;
}

.mb-20px{
  margin-bottom: 20px;
}

.mt-20px{
  margin-top: 20px;
}

.mtb-10{
  margin: 10px 0px 10px 0px ;
}

.p-0{
  padding: 0;
}

.paragraph{
  font-family: "Muli", "Helvetica Neue", sans-serif;
  color: #000000;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 1.5em;
}

.heading{
  font-size: 19px;
  line-height: 27px;
  font-family: "Muli", "Helvetica Neue", sans-serif;
  margin: 0;
  padding: 0;
  color: #2b2222;
}

img.facebook {
  background: #039be5;
  width: 150px;
  height: 45px;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: .3s ease;
}

img.facebook:hover {
  border: 2px solid #039be5;
  background: white;
}

img.whatsapp {
  background: #40c351;
  width: 150px;
  height: 45px;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: .3s ease;
}

img.whatsapp:hover {
  border: 2px solid #40c351;
  background: white;
}

img.pinterest {
  background: #e60023;
  width: 150px;
  height: 45px;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: .3s ease;
}

img.pinterest:hover {
  border: 2px solid #e60023;
  background: white;
}

img.twitter {
  background: #0008ff;
  width: 150px;
  height: 45px;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: .3s ease;
}

img.twitter:hover {
  border: 2px solid #0008ff;
  background: white;
}

@media only screen and (max-width: 600px) {
  img.twitter {
    width: 50px;
    height: 25px;
  }

  img.pinterest {
    width: 50px;
    height: 25px;
  }

  img.whatsapp {
    width: 50px;
    height: 25px;
  }
  
  img.facebook {
    width: 50px;
    height: 25px;
  }

  .media-links.mt-20px{
    gap: 15px !important;
  }

  .main{
    width: 95% !important;
  }

  img.pc-image{
    width: 100% !important;
  }

  .header .main {
    width: 95% !important;
    gap: 5% !important;
  }

  .footer{
    padding: 0px !important;
  }

  .maingrid{
    display: block;
  }
  
  .maingrid .griditem img{
    height: 100%;
  }
}